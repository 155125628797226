<template>
	<div class="base" v-if="dataList[productName]!=null">
		<banner :bannerBackground="dataList[this.productName].banner" :title="dataList[this.productName].title" :title1="dataList[this.productName].title1" :title3="dataList[this.productName].title3" :name="this.productName">
			<template v-slot:bannerButton>
				<div style="display: flex; margin-top: 35px; position: relative;">
					<el-button type="primary" @click="onBtnDownloadClick" class="download">立即下载</el-button>
					<el-image id="qrCode" :src="require('@/images/QRCode.png')" class="qrcode" @mouseover="onqrmouseover" @mouseout="onqrmouseout" v-if="isPC"></el-image>
					<el-image id="dzCode" :src="require('@/images/DZYYDownload.png')" class="app-download" v-show="showQR" v-if="productName=='douzhuanyuyin'"></el-image>
					<el-image id="xbCode" :src="require('@/images/XBSTDownload.png')" class="app-download" v-show="showQR" v-if="productName=='xiaobaosouti'"></el-image>
				</div>
			</template>
		</banner>

		<div class="flex-center xyz-title produce-title">
			{{dataList[productName].produceTitle}}
		</div>

		<div class="flex-center">
			<div class="produce-detail">
				{{dataList[productName].produceDetail}}
			</div>
		</div>


		<div class="flex-center">
			<el-image :src="dataList[productName].logo1" class="logo1">
			</el-image>
		</div>

		<div class="flex-center">
			<el-image :src="dataList[productName].produceImage" class="produce-image">
			</el-image>
		</div>

		<div class="flex-center produce-container">
			<div v-for="(value, index) in dataList[productName].partsProduce.list" :key="index" :class="'part ' + (index==selectedPart?'selected-part':'unselected-part')" :style="{'--selected-bg-color': dataList[productName].partsProduce.selectedBackgroundColor, '--unselected-bg-color': dataList[productName].partsProduce.unSelectedBackgroundColor, '--selected-txt-color': dataList[productName].partsProduce.selectedTextColor, '--unselected-txt-color': dataList[productName].partsProduce.unSelectedTextColor}" @mouseover="onPartProduceClick(index)">
				<div :class="index==selectedPart?'selected-produce-title':'unselected-produce-title'">{{value.title}}</div>
				<div class="produce-desc" v-if="index==selectedPart">{{value.desc}}</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import banner from "@/components/BannerComponent.vue"
import "@/css/Banner.css"
import axios from 'axios'

export default{
	components: {
		banner
	},

	created(){
		this.productName = this.$route.query.name;
		this.$emit('changeTabs', 'products');

		axios.get("/json/product/" + this.productName + ".json")
		.then(res => {
			this.dataList[this.productName] = res.data;
			console.log(this.dataList[this.productName]);
			window.scrollTo({
				top: 0
			})
		})

		this.isOnPC();
	},

	data(){
		return {
			dataList: {},
			productName: "",
			selectedPart: 0,
			showQR: false,
			isPC: false
		}
	},

	methods: {
		onPartProduceClick(index){
			this.selectedPart = index;
		},

		onBtnDownloadClick(){
			window.open(this.dataList[this.productName].download);
		},

		onqrmouseover(){
			this.showQR = true;

		},

		onqrmouseout(){
			this.showQR = false;
		},

		isOnPC(){
			const userAgent = navigator.userAgent;
			if (userAgent.match(/Android/i) || userAgent.match(/iPhone/i)) {
				this.isPC = false;
			} else {
				this.isPC = true;
			}
		}
	}
}
	
</script>

<style type="text/css" scoped>
	.block_inline{
		display: inline-block;
	}

	.download{
		width: 115px;
		height: 35px;
		border-radius: 6px;
		display: inline-block;
	}

	.qrcode{
		width: 35px;
		height: 35px;
		display: inline-block;
		margin-left: 20px;
	}

	.logo1{
		width: 575px;
		height: 36px;
		margin-top: 57px;
	}

	@media screen and (max-width: 575px){
		.logo1{
			width: 100vw;
			height: calc(36 / 575 * 100vw);
			margin-top: 57px;
		}
	}

	.produce-image{
		width: 703px;
		/*height: 1010px;*/
		margin-top: 80px;
	}

	.part{
		margin-right: calc(14 / 1440 * 100vw);
		padding-top: calc(39 / 1440 * 100vw);
		box-sizing: border-box;
		position: relative;
		cursor: pointer;
		border-radius: 11px;
		height: calc(336 / 681 * 100%);
		font-size: calc(12 / 1440 * 100vw);
	}

	.selected-part{
		width: calc(350 / 1440 * 100%);
		min-width: 138.91px;
		min-height: 133.34px;
		background-color: var(--selected-bg-color);
		color: var(--selected-txt-color);
		padding-left: calc(37 / 1440 * 100vw);
	}

	.unselected-part{
		width: calc(124 / 1440 * 100%);
		min-width: 46.63;
		min-height: 133.34px;
		background-color: var(--unselected-bg-color);
		color: var(--unselected-txt-color);
		padding-left: calc(18 / 1440 * 100vw);
	}

	.selected-produce-title{
		font-weight: 600;
		font-size: calc(21 / 1440 * 100vw);
		text-decoration: underline;
		text-underline-offset: calc(5 / 1440 * 100vw);;
		text-decoration-thickness: calc(3 / 1440 * 100vw);
	}

	.unselected-produce-title{
		font-weight: 400;
		font-size: calc(21 / 1440 * 100vw);
	}

	.produce-desc{
		position: absolute;
		bottom: calc(41 / 1440 * 100vw);
		width: calc(229 / 1440 * 100vw);
	}

	.app-download{
		left: 190px;
		width: 100px;
		height: 100px;
		position: absolute;
	}

	@media screen and (max-width: 720px){
		.part{
			font-size: 12px;
			height: calc(504 / 681 * 100%);
		}

		.produce-desc{
			width: auto;
			margin-right: calc(37 / 1440 * 100vw);
		}

		.selected-part{
			width: calc(525 / 1440 * 100%);
		}

		.unselected-part{
			width: calc(186 / 1440 * 100%);
		}

		.selected-produce-title{
			font-size: 15px;
		}

		.unselected-produce-title{
			font-size: 15px;
		}
	}

	.produce-container{
		background-color: rgba(249, 251, 255, 1);
		height: calc(681 / 1440 * 100vw);
	}
</style>









